/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        $('.bk_calendar_frame').next().addClass('hidden');

        booking();
        $('#calendar_booking1').bind("DOMSubtreeModified",function(){
           booking();
        });




        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000);
                return false;
              }
            }
          });


          var swiper = new Swiper('.swiper-gallery', {
            pagination: '.swiper-pagination',
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            slidesPerView: 1,
            paginationClickable: true,
            spaceBetween: 30,
            loop: true
        });
          var swiper2 = new Swiper('.swiper-top', {
              autoplay: 5000,
              slidesPerView: 1,
              spaceBetween: 30,
              loop: true
          });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


function booking(){
  $.each( $('.datepick-days-cell'), function(){
    //vorige
    if( $(this).hasClass('date_approved') && $(this).prev().hasClass('date_available') ){
      if( $(this).hasClass('date_approved')   ){
         $(this).addClass('date_changeover1');
      }
    }
    if( $(this).hasClass('date_approved') && $(this).is(':first-child') ){
      if( $(this).parent().prev().children().last().hasClass('date_available')){
        $(this).addClass('date_changeover1');
      }
    }
    //volgende
    if( $(this).hasClass('date_approved') && $(this).next().hasClass('date_available') ){
       $(this).addClass('date_changeover2');
    }
    if( $(this).hasClass('date_approved') && $(this).is(':last-child') ){
      if( $(this).parent().next().children().first().hasClass('date_available')){
        $(this).addClass('date_changeover2');
      }
    }
  });
}
